export const FIRST_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';
export const MAIN_ROUTE = '/main';
export const BOOK_ROUTE = '/book';
export const ADD_BOOK_ROUTE = '/book';
export const AUTHOR_ROUTE = '/author';
export const ADD_AUTHOR_ROUTE = '/author';
export const AUTHORS_ROUTE = '/authors';
export const NOTE_ROUTE = '/note';
export const MANUAL_ROUTE = '/manual';
export const NOTFOUND_ROUTE = '/404';